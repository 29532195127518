var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"col-lg-5 login-side-col bg-primary"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12 align-self-center"},[_c('img',{staticClass:"my-auto align-self-center",attrs:{"src":require('@/assets/images/logo-ae-w-alt.png')}})])])]),_c('div',{staticClass:"col-lg-7 align-self-center h-100"},[_c('div',{attrs:{"clas":"row justify-items-center"}},[_vm._m(0),_c('div',{staticClass:"col-lg-8 col-md-10 col-12 mx-auto align-self-center"},[_c('div',{staticClass:"p-2 mt-4"},[(_vm.resetConfirmSuccess)?_c('div',{staticClass:"alert alert-info text-left mb-4",attrs:{"role":"alert"}},[_vm._v(" Password dell'account "),_c('b',[_vm._v(_vm._s(_vm.email))]),_vm._v(" modificata con successo"),_c('br')]):_vm._e(),(_vm.resetConfirmSuccess)?_c('div',{staticClass:"my-4 text-center w-100"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/login"}},[_vm._v("Torna alla pagina di login")])],1):_vm._e(),(!_vm.resetConfirmSuccess)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToReset.apply(null, arguments)}}},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"password-1"}},[_vm._v("Nuova password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && _vm.$v.password.$error,
                        },attrs:{"type":"password","id":"password-1","placeholder":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.submitted && _vm.$v.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.password.required)?_c('span',[_vm._v("Campo obbligatorio")]):_vm._e(),(!_vm.$v.password.valid)?_c('span',[_vm._v(" La password deve contenere almeno: una lettera minuscola, una lettera maiuscola, un numero ed un simbolo (#?!@$%^&*-) ")]):_vm._e(),(!_vm.$v.password.minLength)?_c('span',[_vm._v("Password troppo corta (minimo 8 caratteri)")]):_vm._e(),(!_vm.$v.password.maxLength)?_c('span',[_vm._v("Password troppo lunga (massimo 16 caratteri)")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"password-2"}},[_vm._v("Ripeti password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.re_password),expression:"re_password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitted && _vm.$v.re_password.$error,
                        },attrs:{"type":"password","id":"password-2","placeholder":""},domProps:{"value":(_vm.re_password)},on:{"input":function($event){if($event.target.composing)return;_vm.re_password=$event.target.value}}}),(_vm.submitted && _vm.$v.re_password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.re_password.required)?_c('span',[_vm._v("Campo obbligatorio")]):_vm._e(),(!_vm.$v.re_password.sameAs)?_c('span',[_vm._v("Le due password non coincidono")]):_vm._e()]):_vm._e()]),_vm._m(1)]):_vm._e()])]),_c('div',{staticClass:"col-12 align-self-end"},[_c('p',{staticClass:"mt-5 text-center"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" - Powered by Vysio s.r.l. "),_c('br'),_vm._v(" P.IVA: IT05156040759 - Via Carlo Magno 17 73042 Casarano (Le) Italia ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 mx-auto"},[_c('div',{staticClass:"text-center mt-2 align-self-center"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Reimposta la tua password")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Scegli una nuova password per il tuo account AdaptEasy Manager ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-0"},[_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-primary w-sm",attrs:{"type":"submit"}},[_vm._v(" Conferma ")])])])
}]

export { render, staticRenderFns }