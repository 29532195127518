<script>
import appConfig from "@/app.config";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import API from '@/api.js';

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Reset Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      resetConfirmSuccess: false,
      password: "",
      re_password: "",
      uuid: this.$route.params.uuid,
      token: this.$route.params.token,
      submitted: false,
      error: null,
      title: "Resetpwd",
    };
  },
  validations: {
    password: {
      required,
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[#?!@$%^&*-]/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
      },
      minLength: minLength(8),
      maxLength: maxLength(16)
    },
    re_password: {
      required,
      sameAs: sameAs("password")
    }
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    async tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          const resp = await this.axios.post(
            API.resetPasswordConfirm.path(),
            {
              uid: this.uuid,
              token: this.token,
              new_password: this.password
            }
          );
          if(resp.status === 204) {
            // Success
            this.resetConfirmSuccess = true;
          }
        } catch (e) {
          // error
          console.log(e);
        }
      }
    },
  },
  mounted() {
    if (this.$auth.check()) {
      // this.$router.push('/');
      this.$auth.logout();
    }
  },
};
</script>

<template>
  <div class="container-fluid" style="overflow-x: hidden;">
    <div class="row" style="min-height: 100vh;">
      <div class="col-lg-5 login-side-col bg-primary">
        <div class="row h-100">
          <div class="col-12 align-self-center">
            <img class="my-auto align-self-center" :src="require('@/assets/images/logo-ae-w-alt.png')" />
          </div>
        </div>
      </div>
      <div class="col-lg-7 align-self-center h-100" style="">
          <div clas="row justify-items-center">
            <div class="col-6 mx-auto">
              <div class="text-center mt-2 align-self-center">
                  <h3 class="text-primary">Reimposta la tua password</h3>
                  <p class="text-muted">
                    Scegli una nuova password per il tuo account AdaptEasy Manager
                  </p>
                </div>
            </div>
            <div class="col-lg-8 col-md-10 col-12 mx-auto align-self-center">
              <div class="p-2 mt-4">
                    <div
                          class="alert alert-info text-left mb-4"
                          role="alert"
                          v-if="resetConfirmSuccess"
                        >
                      Password dell'account <b>{{ email }}</b> modificata con successo<br/>
                    </div>
                    <div class="my-4 text-center w-100" v-if="resetConfirmSuccess">
                      <router-link class="btn btn-primary" to="/login">Torna alla pagina di login</router-link>
                    </div>
                    <form @submit.prevent="tryToReset" v-if="!resetConfirmSuccess">
                      <div class="mb-3">
                        <label for="password-1">Nuova password</label>
                        <input
                          type="password"
                          v-model="password"
                          class="form-control"
                          id="password-1"
                          placeholder=""
                          :class="{
                            'is-invalid': submitted && $v.password.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.password.required"
                            >Campo obbligatorio</span>
                          <span v-if="!$v.password.valid"
                            >
                            La password deve contenere almeno: una lettera minuscola, una lettera maiuscola, un numero
                            ed un simbolo (#?!@$%^&*-)
                          </span>
                          <span v-if="!$v.password.minLength"
                            >Password troppo corta (minimo 8 caratteri)</span>
                          <span v-if="!$v.password.maxLength"
                            >Password troppo lunga (massimo 16 caratteri)</span>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="password-2">Ripeti password</label>
                        <input
                          type="password"
                          v-model="re_password"
                          class="form-control"
                          id="password-2"
                          placeholder=""
                          :class="{
                            'is-invalid': submitted && $v.re_password.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.re_password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.re_password.required"
                            >Campo obbligatorio</span
                          >
                          <span v-if="!$v.re_password.sameAs"
                            >Le due password non coincidono</span
                          >
                        </div>
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-center">
                          <button class="btn btn-primary w-sm" type="submit">
                            Conferma
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
            </div>
            <div class="col-12 align-self-end">
              <p class="mt-5 text-center">
                © {{ new Date().getFullYear() }} - Powered by Vysio s.r.l. <br/> P.IVA: IT05156040759 - Via Carlo Magno 17 73042 Casarano (Le) Italia
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-login {
  background-color: #171f36;  
}
.login-side-col {
  text-align: center;
}
.login-side-col img {
    width: 40%;
}
</style>
